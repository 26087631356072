module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-3NB2F4KL14","head":true,"anonymize":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[],"excludePattern":"(http)"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/images/favicon-192x192.png","name":"Impulse Partners","short_name":"Impulse Partners","description":"We connect corporate groups with startups that are relevant, mature and capable of delivering. We connect startups with clients depending on their fields of expertise.","start_url":"/","lang":"en","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","localize":[{"start_url":"/fr/","lang":"fr","description":"Nous créons une passerelle entre grands groupes et start-up afin de répondre aux enjeux de transformation des uns et de développement des autres. Nous favorisons les conditions de la rencontre et de la collaboration."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0cc006c08acf4ad59cf7d831b00747bc"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
